import React, { createContext, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Employees from "./pages/employees";
import Dashboard from "./pages/dashboard";
import Employee from "./pages/employee";
import Projects from "./pages/projects";
import Settings from "./pages/settings";
import Directory from "./pages/directory";

import { useAuth0 } from "@auth0/auth0-react";
import Landing from "./landing";
import UserContext from "./components/TheUser";
import DefaultLayout from "./layout/default";
import Login from "./login";
import NotFound from "./404";

import { ConvexProvider, ConvexReactClient } from "convex/react";
import Tasks from "./pages/tasks";
import NewEmployee from "./pages/newEmployee";
import Profile from "./pages/profile";
import Reports from "./pages/reports";
import Payments from "./pages/payments";
import FinanceReport from "./pages/report/financeReport";
import Timesheet from "./pages/timesheet";
import Payroll from "./pages/payroll";
import NewPayroll from "./pages/newPayroll";
import TheEmployee from "./pages/theEmployee";
import Test from "./pages/test";
import AccountReceivableByUser from "./pages/report/payments/account-receivable-by-user";
import AccountReceivableByMonth from "./pages/report/payments/account-receivable-by-month";
import AccountReceivableIkat from "./pages/report/payments/account-receivable-ikat";
import OutstandingPayments from "./pages/report/outstanding-payments";
import YTDStatement from "./pages/report/payroll/ytd-statement";
import PayrollDetails from "./pages/report/payroll/payroll-details";
import PayrollSummary from "./pages/report/payroll/payroll-summary";
import MonthlySnapshotReport from "./pages/report/payroll/monthly-snapshort-report";
import Invoices from "./pages/report/payments/invoices";
import Invoice from "./pages/report/payments/invoice";
import BulkPayments from "./pages/report/payments/bulk";
import AdjustPayments from "./pages/report/payments/adjust";
import TimesheetDetails from "./pages/timesheetDetails";
import PayrollItem from "./pages/payrollItem";
import ImportPayroll from "./pages/importPayroll";
import TimesheetResourcsewise from "./pages/report/timesheet/timesheetResourcsewise";
import TimesheetMonthwise from "./pages/report/timesheet/timesheetMonthwise";

function App() {
    const { isAuthenticated, user, loginWithRedirect, logout, loginWithPopup } = useAuth0();

    const convex = new ConvexReactClient(process.env.REACT_APP_CONVEX_URL as string);

    return (
        <>
            <ConvexProvider client={convex}>
                <UserContext.Provider value={{ user: user }}>
                    <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/landing" element={<Landing />} />
                        {/* <Route path="*" element={<PageNotFound />} /> */}
                        {/* <Route path="/login" element={<Login />} /> */}

                        <Route element={<DefaultLayout />}>
                            <Route path="/home" element={<Dashboard />} />
                            <Route path="/dashboard" element={<Dashboard />} />

                            <Route path="/employees" element={<Employees />} />
                            <Route path="/employees/:handle" element={<Employee />} />
                            <Route path="/employee/:handle" element={<TheEmployee />} />
                            <Route path="/employees/new" element={<NewEmployee />} />

                            <Route path="/projects" element={<Projects />} />
                            <Route path="/settings" element={<Settings />} />

                            <Route path="/tasks" element={<Tasks />} />

                            <Route path="/payments" element={<Payments />} />
                            <Route path="/payments/receivable/user" element={<AccountReceivableByUser />} />
                            <Route path="/payments/receivable/month" element={<AccountReceivableByMonth />} />
                            <Route path="/payments/receivable/ikat" element={<AccountReceivableIkat />} />

                            <Route path="/payments/outstanding" element={<OutstandingPayments />} />

                            <Route path="/payments/invoice" element={<Invoices />} />
                            <Route path="/payments/invoice/:uuid/:_m/:_y/:_invNo" element={<Invoice />} />
                            <Route path="/payments/invoice/:uuid/:_w/:_m/:_y/:_invNo" element={<Invoice />} />

                            <Route path="/payments/bulk" element={<BulkPayments />} />
                            <Route path="/payments/adjust" element={<AdjustPayments />} />

                            <Route path="/test" element={<Test />} />

                            <Route path="/reports" element={<Reports />} />
                            <Route path="/reports/finance" element={<FinanceReport />} />

                            <Route path="/profile" element={<Profile />} />

                            <Route path="/payroll" element={<Payroll />} />
                            <Route path="/payroll/new" element={<NewPayroll />} />
                            <Route path="/payroll/ytd" element={<YTDStatement />} />
                            <Route path="/payroll/month" element={<MonthlySnapshotReport />} />
                            <Route path="/payroll/summary" element={<PayrollSummary />} />
                            <Route path="/payroll/detail" element={<PayrollDetails />} />
                            <Route path="/payroll/:uuid" element={<PayrollItem />} />
                            <Route path="/payroll/import" element={<ImportPayroll />} />

                            <Route path="/timesheet" element={<Timesheet />} />
                            <Route path="/timesheet/statement/user" element={<TimesheetResourcsewise />} />
                            <Route path="/timesheet/statement/month" element={<TimesheetMonthwise />} />
                            <Route path="/timesheet/:uuid" element={<TimesheetDetails />} />

                            {/* <Route path="/timesheet" element={<Timesheet />} /> */}
                            {/* <Route path="/directory" element={<Directory />} /> */}
                        </Route>

                        <Route path="*" element={<NotFound />} />

                        {/* 
                            {/* <Route path="/information" element={<Information />} />
                            <Route path="/profile" element={<Profile />} />
                    */}
                    </Routes>
                </UserContext.Provider>
            </ConvexProvider>
        </>
    );
}

export default App;
