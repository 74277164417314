export const sectionClass = "px-4 pt-5 py-0 mx-auto sm:px-6 lg:px-8";

export const button = " font-medium text-center rounded-lg focus:outline-none focus:ring-0 focus:ring-offset-0";

export const buttonXS = "px-3 py-2 text-xs";
export const buttonSM = "px-3 py-2 text-sm";
export const buttonBase = "px-5 py-2.5 text-sm";
export const buttonLG = "px-5 py-3 text-base";
export const buttonXL = "px-6 py-3.5 text-base";

// px-4 py-2 text-sm
//text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
// focus:ring-blue-300
export const blueBgClr = "font-medium text-white bg-blue-700 border border-gray-300 rounded-md shadow-sm  hover:bg-blue-800   focus:ring-transparent";
export const alternativeBgClr = "text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700";
export const darkBgClr = "text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700";
export const lightBgClr = "text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700";
export const greenBgClr = "focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800";
export const redBgClr = "focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900";
export const yellowBgClr = "focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900";
export const purpleBgClr = "focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900";

export const paymentsCommands = [
    { id: 11, name: "Payment Invoices", category: "Actions", url: "/payments/invoice" },
    { id: 0, name: "Payment Calendar", category: "Actions", url: "/payments" },
    { id: 12, name: "Bulk Payment", category: "Actions", url: "/payments/bulk" },
    { id: 12, name: "Payment Biling Adjustments", category: "Actions", url: "/payments/adjust" },
    { id: 1, name: "Resourcewise Account Recievable Statement", category: "Statement", url: "/payments/receivable/user" },
    { id: 2, name: "Monthly Account Recievable Statement", category: "Statement", url: "/payments/receivable/month" },
    { id: 3, name: "Ikat Account Recievable Statement", category: "Statement", url: "/payments/receivable/ikat" },
    { id: 4, name: "Outstanding Payments / Invoices", category: "Statement", url: "/payments/outstanding" },
    { id: 21, name: "Monthly Payment Projections", category: "Forecast", url: "" },
];

export const timesheetCommands = [
    { id: 1, name: "Resourcewise Timesheet Statement", category: "Statement", url: "/timesheet/statement/user" },
    { id: 2, name: "Monthly Timesheet Statement", category: "Statement", url: "/timesheet/statement/month" },
];

export const payrollCommandActions = [
    { id: 0, name: "New Payroll", category: "Actions", url: "/payroll/new" },
    { id: 0, name: "Import Payroll", category: "Actions", url: "/payroll/import" },
    // { id: 1, name: "Active Employees", category: "Modules", url: "/employees" },
    // { id: 2, name: "Monthly Snapshot Report", category: "Report", url: "/payroll/month" },
    { id: 3, name: "Payroll Summary Statement", category: "Statement", url: "/payroll/summary" },
    { id: 4, name: "Payroll Details Statement", category: "Statement", url: "/payroll/detail" },
    { id: 5, name: "YTD Statement", category: "Statement", url: "/payroll/ytd" },
];
