import React, { useEffect, useState } from "react";
import Breadcrums from "../../../components/breadcrums";
import { paymentsBulkBreadcrums, paymentsGenerateInvoiceBreadcrums } from "../../../constants/breadcrums";
import PageTitle from "../../../components/pageTitle";
import { paymentsCommands, sectionClass } from "../../../constants/common";
import CommonFilters from "../../../blocks/commonFilters";
import { useSnackbar } from "react-simple-snackbar";
import { listOfEmployees } from "../../../api/consultants-api";
import { getFinanceReportData, markVerified, performPaymentAPI } from "../../../api/finance-api";
import TanstackTable from "../../../components/TanstackTable";
import { ColumnDef } from "@tanstack/react-table";
import Currency from "react-currency-formatter";
import { FinanceRecord, InvoiceRequestParams } from "../../../model";
import moment from "moment";

export default function BulkPayments() {
    const [people, setPeople] = useState([]);
    const [openSnackbar] = useSnackbar();
    const [isLoading, setLoading] = React.useState(false);

    const [data, setData] = React.useState([]);

    useEffect(() => {
        setLoading(true);

        setPeople([]);
        setData([]);

        getFinanceReportData({
            checkDate: new Date(),
            employee: "",
        }).then((data) => {
            console.log(data);
            setData(data);
        });

        listOfEmployees()
            .then((data) => setPeople(data))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function applyFilters(filters): any {
        console.log(filters);

        setLoading(true);
        setData([]);

        getFinanceReportData(filters)
            .then((data) => {
                setData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const columns = React.useMemo<ColumnDef<FinanceRecord, any>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
            // {
            //     accessorKey: "rate",
            //     header: "Rate (USD)",
            //     cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
            //     enableGrouping: false,
            //     enableColumnFilter: false,
            //     meta: {
            //         cellClassName: "text-right",
            //     },
            // },
            // {
            //     accessorKey: "hrs",
            //     header: "Efforts (Hrs)",
            //     cell: (info) => Number(info.getValue()) | 0,
            //     enableGrouping: false,
            //     enableColumnFilter: false,
            //     meta: {
            //         cellClassName: "text-right",
            //     },
            // },
            // {
            //     accessorKey: "month",
            //     header: "Month",
            //     cell: (info) => info.getValue(),
            //     // enableHiding: false,
            //     enableColumnFilter: false,
            //     enableGrouping: false,
            //     // columnVisibility: false,
            //     meta: {
            //         cellClassName: "text-center",
            //     },
            // },
            {
                accessorKey: "bill",
                header: "Bill Amount (USD)",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
            },
            {
                accessorKey: "payment",
                header: "Amount Recieved (USD)",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
            },
            {
                accessorKey: "diff",
                header: "Balance",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
            },
            {
                accessorKey: "uuid",
                header: "",
                cell: ({ row }) => (
                    <>
                        {row.original.uuid && row.original.diff > 0 && (
                            <div className="flex">
                                <a
                                    href="#"
                                    onClick={(e) =>
                                        performVerify({
                                            uuid: row.original.uuid,
                                            checkDate: moment(row.original._d).toDate(),
                                        })
                                    }
                                    className="text-blue-600 no-underline hover:text-blue-900 no-line-through"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                                    </svg>
                                </a>

                                <a
                                    href="#"
                                    onClick={(e) =>
                                        performPayment({
                                            uuid: row.original.uuid,
                                            checkDate: moment(row.original._d).toDate(),
                                        })
                                    }
                                    className="ml-3 text-blue-600 no-underline hover:text-blue-900 no-line-through"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                                        />
                                    </svg>
                                </a>

                                {/* {row.original.invoice_no && (
                                    <a target="_blank" href={`/payments/invoice/${row.original.uuid}/${row.original.m}/${row.original.year}/${row.original.invoice_no}`} className="text-blue-600 no-underline hover:text-blue-900 no-line-through">
                                        View <span className="sr-only">, {row.original.invoice_no}</span>
                                    </a>
                                )} */}
                            </div>
                        )}
                    </>
                ),
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
            },
        ],
        []
    );

    function performVerify(params: InvoiceRequestParams): any {
        console.log(params);

        params.verified = true;
        params._ts_verify = new Date();

        markVerified(params).then((r) => {
            console.log(r);
            openSnackbar("Verified Sucessfully");
        });
    }

    function performPayment(params: InvoiceRequestParams): any {
        console.log(params);

        // params.verified = true;
        // params._ts_verify = new Date();

        performPaymentAPI(params).then((r) => {
            console.log(r);
            openSnackbar("Payments Completed.");
        });
    }

    return (
        <>
            <main>
                <Breadcrums breadcrumLinks={paymentsBulkBreadcrums} groups={["Actions"]} commands={paymentsCommands}></Breadcrums>
                <PageTitle title="Bulk Payments"></PageTitle>

                <section className={sectionClass}>
                    <CommonFilters people={people} applyFilters={applyFilters}></CommonFilters>

                    {data.length > 0 && <TanstackTable data={data} columns={columns} downloadBtn={true}></TanstackTable>}
                </section>
            </main>
        </>
    );
}
